<template>
	<div>
		<el-row class="tac" :gutter="10">
			<el-col :span="8">
				<el-menu class="menu-nav" :default-active="currentPath" @open="handleOpen" @close="handleClose"
					unique-opened background-color="#20222A" active-text-color="#ffd04b" text-color="#fff" router
					:collapse="isCollapse" style="height: 100%; border: none">
					<!-- <el-submenu index="1"> -->
					<!-- <template slot="title"> -->
					<el-menu-item index="/home">
						<i class="el-icon-location"></i>
						<span>管理后台</span>
					</el-menu-item>
					<!-- </template> -->
					<!-- <el-menu-item-group> -->
					<!-- <template slot="title">分组一</template> -->
					<!-- <el-menu-item index="1-1">选项1</el-menu-item> -->
					<!-- <el-menu-item index="1-2">选项2</el-menu-item> -->
					<!-- </el-menu-item-group> -->
					<!-- </el-submenu> -->

					<el-submenu index="3">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">代理管理</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/Didaudit">
								<i class="el-icon-warning"></i>
								<span slot="title">待审核</span>
							</el-menu-item>
							<el-menu-item index="/Alreadyagree">
								<i class="el-icon-circle-check"></i>
								<span slot="title">已同意</span>
							</el-menu-item>
							<el-menu-item index="/Alreadyrefuse">
								<i class="el-icon-circle-close"></i>
								<span slot="title">已拒绝</span>
							</el-menu-item>
							<!-- <el-menu-item index="/equipment">
								<i class="el-icon-circle-plus-outline"></i>
								<span slot="title">添加代理</span>
							</el-menu-item> -->
							<el-menu-item index="/agentList">
								<i class="el-icon-menu"></i>
								<span slot="title">代理列表</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>

					<el-submenu index="4">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">广告主管理</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/advUserList">
								<i class="el-icon-menu"></i>
								<span slot="title">广告主列表</span>
							</el-menu-item>
							<el-menu-item index="/advListApply">
								<i class="el-icon-menu"></i>
								<span slot="title">广告主申请</span>
							</el-menu-item>
							<el-menu-item index="/advList">
								<i class="el-icon-menu"></i>
								<span slot="title">广告列表</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>
					
					<el-submenu index="99">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">广告服务商管理</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/advAgencyList">
								<i class="el-icon-menu"></i>
								<span slot="title">广告服务商列表</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>
					
					<el-submenu index="14">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">实名认证管理</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/realauthList">
								<i class="el-icon-menu"></i>
								<span slot="title">实名认证列表</span>
							</el-menu-item>
							<el-menu-item index="/appealList">
								<i class="el-icon-menu"></i>
								<span slot="title">实名认证申诉列表</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>

					<el-menu-item index="eduBureauList">
						<i class="el-icon-medal-1"></i>
						<span slot="title">教育局管理</span>
					</el-menu-item>
					<el-submenu index="5">
						<template slot="title">
							<i class="el-icon-document"></i>
							<span slot="title">学校管理</span>
						</template>
						<el-menu-item-group>
							<!-- <el-menu-item index="/school">
                <i class="el-icon-circle-plus-outline"></i>
                <span slot="title">添加学校</span>
              </el-menu-item> -->
							<el-menu-item index="/schoolList">
								<i class="el-icon-menu"></i>
								<span slot="title">学校列表</span>
							</el-menu-item>
							<el-menu-item index="/schoolInfo">
								<i class="el-icon-document"></i>
								<span slot="title">学校数据</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>

					<el-submenu index="2">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span slot="title">文章管理</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/articleComplete">
								<i class="el-icon-warning"></i>
								<span slot="title">待审核</span>
							</el-menu-item>
							<el-menu-item index="UNarticle">
								<i class="el-icon-circle-check"></i>
								<span slot="title">已审核</span>
							</el-menu-item>
							<el-menu-item index="refuseArticle">
								<i class="el-icon-circle-close"></i>
								<span slot="title">已拒绝</span>
							</el-menu-item>
							<el-menu-item index="freeze">
								<i class="el-icon-lock"></i>
								<span slot="title">已冻结</span>
							</el-menu-item>
							<el-menu-item index="Addclassify">
								<i class="el-icon-circle-plus-outline"></i>
								<span slot="title">添加分类</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>
					<el-submenu index="11">
						<template slot="title">
							<i class="el-icon-document"></i>
							<span slot="title">APP管理</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="appAdmin">
								<i class="el-icon-edit"></i>
								<span slot="title">APP首页数据修改</span>
							</el-menu-item>
							<el-menu-item index="appUpdate">
								<i class="el-icon-refresh"></i>
								<span slot="title">APP版本更新管理</span>
							</el-menu-item>
							<el-menu-item index="userList">
								<i class="el-icon-menu"></i>
								<span slot="title">用户列表</span>
							</el-menu-item>
							<el-menu-item index="pushNews">
								<i class="el-icon-edit-outline"></i>
								<span slot="title">发布文章</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>
					<el-menu-item index="machine">
						<i class="el-icon-menu"></i>
						<span slot="title">设备列表</span>
					</el-menu-item>
					<el-menu-item index="activityInfo">
						<i class="el-icon-medal-1"></i>
						<span slot="title">活动排名</span>
					</el-menu-item>
				</el-menu>
			</el-col>
		</el-row>
	</div>
</template>
<script>
	export default {
		props: ['isCollapse'],
		data() {
			return {
				currentPath: location.pathname
			}
		},
		methods: {
			handleOpen(key, keyPath) {
				// console.log(key, keyPath)
			},
			handleClose(key, keyPath) {
				// console.log(key, keyPath)
			}
		}
	}
</script>
<style lang="scss" scoped>
	.menu-nav:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;
		text-align: left;
	}

	.el-menu {
		border: none;
	}
</style>
